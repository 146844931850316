.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.5rem;
  user-select: none;
  transform: translate(-50%, -86%);
  &:hover {
    z-index: 1;
  }
}
